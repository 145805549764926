export const queryFragmentSeo = {
  seo: {
    populate: {
      image: '*'
    }
  }
}

export const queryFragmentBlocks = {
  blocks: {
    // TODO specify fields
    populate: {
      links: {
        populate: {
          bulletinBoardEntry: {
            fields: ['title', 'slug']
          },
          calendarEntry: {
            fields: ['title', 'slug']
          },
          communityEntry: {
            fields: ['displayName', 'slug']
          },
          organisationEntry: {
            fields: ['title', 'slug']
          },
          pageEntry: {
            fields: ['title', 'slug']
          },
          projectEntry: {
            fields: ['title', 'slug']
          }
        }
      },
      files: {
        populate: '*'
      },
      summaries: {
        populate: '*'
      },
      details: {
        populate: '*'
      },
      listItems: {
        populate: '*'
      }
    }
  }
}

export const queryFragmentFirstRichTextBlock = {
  on: {
    'blocks.rich-text': {
      fields: ['richText'],
      filters: {
        richText: {
          $notNull: true
        }
      }
    }
  }
}
