<template>
  <div :class="$style.root">
    <code
      v-if="isDevelopment && error"
      v-html="error"
    />
    <div v-else-if="!isDevelopment">
      {{ $t('error.public.message') }}
    </div>
  </div>
</template>

<script lang="ts" setup>
  const props = defineProps({
    error: {
      type: Object,
      required: true,
    }
  })

  // note extra
  const runtimeConfig = useRuntimeConfig()
  const env = runtimeConfig.env || runtimeConfig.public.env
  const isDevelopment = env?.toLowerCase() === 'development'
</script>

<style module>
.root {
  padding: var(--unit--default);
}
</style>
